import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ProductService {
    constructor(private http: HttpClient) { }

    public baseUrl: String = environment.baseUrl
    public ownerId = "5f6f0e8c1f46bf3f11660245"
    // public shopId = "5e683e91ec56557a4c2fdf62";
    // public shopId = "5e92d76158e229ab644e6b94";
    // public shopId = localStorage.getItem('shopID')

    getProductList(shopId,params={}) {

        let url = this.baseUrl + `/api/v1/Customer/${this.ownerId}/5f6f25f41f46bf3f11660246/ProductsList/food/100/0?&${params}`
        console.log(url,"url");
        let response;
        return this.http.get(url).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getHotDealProductList() {
        // let url = this.baseUrl + `/api/v1/admin/${this.ownerId}/5f6f25f41f46bf3f11660246/ProductsList/food/100/0?&isHotDeal=true`
       let url = this.baseUrl +`/api/v1/admin/all/5f6f0e8c1f46bf3f11660245/ProductsList/20/0?&isHotDeal=true&filter=`
        console.log(url,"url");
        let response;
        return this.http.get(url).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }



    registerTempUser(payload){
        payload.ownerId = this.ownerId;
        let response;
        return this.http.post(this.baseUrl+'/api/v1/customer/Register', payload).pipe(
            map((data) => {
                response = data
                console.log(response, "response")
                return response.object
            })
        );
    }


   getWallet(userId){
    let response;
    return this.http.get(this.baseUrl + `/api/v1/Customer/getWallet/${this.ownerId}/${userId}`).pipe(
      map((data) => {
        response = data;
        return response.object;
      })
    );

  }

    listOffer(filter) {
      let response;
      return this.http
        .get(this.baseUrl + `/api/v1/admin/listOffer/5f6f0e8c1f46bf3f11660245?&filter=${filter}`)
        .pipe(
          map((data) => {
            response = data;
            return response.object;
          })
        );
    }


    getCashBacks(userId){
      let response;
      return this.http
        .get(this.baseUrl + `/api/v1/Customer/getReedemCashback/${userId}`)
        .pipe(
          map((data) => {
            response = data;
            return response.object;
          })
        );


    }

    getOwnerId() {
        return this.ownerId
    }

    getProductDetails(productId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/getProductDetails/${productId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    addToCart(action, payload) {
        let response;
        return this.http.put(this.baseUrl + `/api/v1/customer/Cart/${action}`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    cartList(userId,shopId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/customer/CartList/${userId}/${shopId}/0/10`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getCheckSum(payload){
      let response;
      return this.http.get(this.baseUrl + `/api/v1/Customer/Payment/${payload.mobile}/${payload.email}/${payload.customer_id}/${payload.amount}`).pipe(
          map((data) => {
              response = data
              return response.object
          })
      );

    }

    AddToWishCart(action, payload) {
        let response;
        return this.http.put(this.baseUrl + `/api/v1/customer/WishListCart/${action}`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getAllWishList(userId,shopId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/customer/GetAllWishLists/${userId}/${shopId}/0/10`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    SaveAddress(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/common/saveAddress`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getAddress(userId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/common/getAddress/${userId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );

    }

    getAddressDetails(userId,addressId) {
      let response;
      return this.http.get(this.baseUrl + `/api/v1/common/getAddressDetails/${userId}/${addressId}`).pipe(
          map((data) => {
              response = data
              return response.object
          })
      );

  }

    listSubCategory() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/listSubCategory/${this.ownerId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    listIns() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/Insurance`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }








    orderProduct(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/customer/createOrder`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    saveQuery(payload){
        payload.ownerId = this.ownerId;
        let response;
        return this.http.post(this.baseUrl + `/api/v1/customer/saveQuery`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getOrderList(page,userId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/Customer/getOrderList/${userId}/${page.limit}/${page.skip}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );

    }

    createComplaint(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/Customer/SaveUserComplaint`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getAllReviewList(userId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/Customer/ProductReviewList/${userId}/100/0`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    saveReview(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/Customer/GiveProductReview`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    search(filter,shopId){
        let response;
        return this.http.get(this.baseUrl + `/api/v1/${this.ownerId}/${shopId}/customer/search?search=${filter.search}&filter=${filter.filter}`).pipe(
          map((data) => {
            response = data
            return response.object
          })
        );
      }



    getOrderDetails(orderId) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/getOrderData/${orderId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }


     fetchStoreList() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/getAllStore/${this.ownerId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    feedback(payload){
      let response;
      return this.http.post(this.baseUrl + `/api/v1/Customer/GiveFeedBack`,payload).pipe(
          map((data) => {
              response = data
              return response.object
          })
      );

    }


    getAdminProfile() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/profile/getAdminProfile/${this.ownerId}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }





    //









    listCategory() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/listCategory`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    createCategory(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/admin/createCategory`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    editCategory(id, payload) {
        let response;
        return this.http.put(this.baseUrl + `/api/v1/admin/editCategory/${id}`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    deleteCategory(id) {
        let response;
        return this.http.delete(this.baseUrl + `/api/v1/admin/deleteCategory/${id}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    //


    fetchProductList(params) {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/${params.shopId}/ProductsList/all/all/10/0`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    fetchProductListMenu(params,page) {
      let response;
      return this.http
        .get(
          this.baseUrl +
            `/api/v1/admin/${params.shopId}/${this.ownerId}/ProductsList/${page.limit}/${page.skip}?&filter=${params.filter}`
        )
        .pipe(
          map((data) => {
            response = data;
            return response.object;
          })
        );
    }


    createSubCategory(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/admin/createSubCategory`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    editSubCategory(id, payload) {
        let response;
        return this.http.put(this.baseUrl + `/api/v1/admin/editSubCategory/${id}`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    deleteSubCategory(id) {
        let response;
        return this.http.delete(this.baseUrl + `/api/v1/admin/deleteSubCategory/${id}`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    assignProductToCategory(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/admin/addCategoryToProduct`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }

    getMenuList() {
        let response;
        return this.http.get(this.baseUrl + `/api/v1/admin/getMenuList`).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );
    }




}
