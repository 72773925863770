// import { Push } from '@ionic-native/push/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ProductDetailsPageModule } from './pages/productDetails/productDetails.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { TokenInterceptor } from "../app/core/interceptors/token.interceptor";
import { JwtInterceptor } from "../app/core/interceptors/jwt.interceptor"
import { AppSpinnerModule } from "../app/core/app-spinner/app-spinner.module";
import { IonicImageLoader } from 'ionic-image-loader';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
// import { Facebook } from '@ionic-native/facebook/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ProductDetailsPageModule,
    FormsModule,
    AppSpinnerModule,
    IonicModule.forRoot(),
    IonicImageLoader.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    BrowserAnimationsModule
  ],
  declarations: [AppComponent],
  providers: [InAppBrowser,FileTransfer, SplashScreen,SocialSharing,
     StatusBar,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }
  ,GooglePlus,WebView,
  File,
  FileOpener,
  AppVersion
  // SocialSharing
  // Facebook
],
  bootstrap: [AppComponent]
})
export class AppModule {}
