import {EventEmitter, Injectable} from '@angular/core';
import {LoaderState} from './lib/loader-state.model';

@Injectable()
export class PageLoaderService {

  // tslint:disable-next-line:variable-name
  private _loaderState: EventEmitter<LoaderState> = new EventEmitter<LoaderState>();

  private counter = 0;

  constructor() {
  }

  get loaderState() {
    return this._loaderState.asObservable();
  }


  public enable() {
    this.counter++;
    const loaderState = {
      show: true
    };
    this._loaderState.emit(<LoaderState>loaderState);
  }

  public disable() {
    const loaderState = {
      show: false
    };
    // const loaderState = new LoaderState();
    // loaderState.show = false;
    // this._loaderState.emit(<LoaderState>loaderState);

    this.counter--;
    if (this.counter === 0) {
      this._loaderState.emit(loaderState as LoaderState);
    } else if (this.counter < 0) {
      console.warn('Counter is stuck, value can not be less then zero. Please check you have enabled & disabled the loader in pairs');
      this.counter = 0;
      this._loaderState.emit(loaderState as LoaderState);
    }
  }

  next(value: boolean): void {
    if (value) {
      this.enable();
    } else {
      this.disable();
    }
  }
}
