import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

import { MenuController, Platform, ToastController } from "@ionic/angular";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Storage } from "@ionic/storage";
// import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

import { UserData } from "./providers/user-data";
import { timer } from "rxjs";
import { Location } from "@angular/common";

import { ImageLoaderConfigService } from "ionic-image-loader";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { ProductService } from "./providers/product.services";
// import { FCM } from "@ionic-native/fcm/ngx";
import { AlertController } from "@ionic/angular";
import { AppVersion } from "@ionic-native/app-version/ngx";

declare var window: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public shopName;
  public showSpash = true;
  public selectedTheme = "light";
  public userName = "Guest";
  public userImage = "";
  private _Share;

  appPages = [
    {
      title: "Home",
      url: "/tabs/home",
      icon: "home-outline",
    },
    {
      title: "All Cars",
      url: "/tabs/menu",
      icon: "car-sport-outline",
    },
    {
      title: "Brands",
      url: "/tabs/category",
      icon: "apps",
    },
    // {
    //   title: "Car Compare",
    //   url: "/compare/null",
    //   icon: "git-compare-outline",
    // },
  ];
  loggedIn = false;
  dark = false;
  backButtonSubscription;
  public storeList: any = [];

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private _UserData: UserData,
    private location: Location,
    private imageLoaderConfig: ImageLoaderConfigService,
    private _ProductService: ProductService,
    private alertController: AlertController,
    private appVersion: AppVersion,
    // private keyboard: Keyboard,
    // private fcm: FCM,
    // private push: Push,
    private toastCtrl: ToastController,
    private socialSharing: SocialSharing
  ) {
    if (!localStorage.getItem("AuthToken")) {
    
      this.registerTempUser();
    }
    // disable spinners by default, you can add [spinner]="true" to a specific component instance later on to override this
    imageLoaderConfig.enableSpinner(true);
    console.log(
      imageLoaderConfig.spinnerEnabled,
      "imageLoaderConfig.spinnerEnabled"
    );
    imageLoaderConfig.spinnerColor = "red";

    // set the maximum concurrent connections to 10
    imageLoaderConfig.setConcurrency(10);
    // this could be useful while trying to debug issues with the component
    imageLoaderConfig.enableDebugMode();
    // imageLoaderConfig.setDisplay('inline-block');
    // imageLoaderConfig.useImageTag(true);

    imageLoaderConfig.setBackgroundSize("cover");

    this.imageLoaderConfig.setConcurrency(5);

    this.imageLoaderConfig.setMaximumCacheSize(200 * 1024 * 1024); // set max size to 20MB

    this.imageLoaderConfig.setMaximumCacheAge(365 * 24 * 60 * 60 * 1000); // 7 days

    this.imageLoaderConfig.enableFallbackAsPlaceholder(true);
    this.imageLoaderConfig.setFileNameCachedWithExtension(true);
  }

  registerTempUser() {
    let tempName: any = Math.random();
    tempName = tempName.toString().slice(5, 10);
    let payload = {
      name: "Guest" + tempName,
      fullName: "Guest" + tempName,
      email: "guest" + tempName + "@a2zcar.in",
      password: "guest",
      mobile: "1000000000",
    };
    this._ProductService.registerTempUser(payload).subscribe((result: any) => {
      // console.log("00 result", result);
      console.log("_id", result.customerDetails._id);
      localStorage.setItem("userId", result.customerDetails._id);
      localStorage.setItem("AuthToken", result.authToken);
      console.log(localStorage.getItem("AuthToken"));
      this.initializeApp();
     
    });
  }

  ngAfterViewInit() {
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      if (window.location.href.includes("home")) {
        navigator["app"].exitApp();
      } else {
        this.location.back();
      }
    });
  }

  public searchproductList: any = [];
  async ngOnInit() {
    
  }

  checkApplicationUpadte() {
    this.platform.ready().then(() => {
      let appVersion = "";
      this._ProductService.getAdminProfile().subscribe((result: any) => {
        if (result) {
          let adminProfile = result["data"];

          console.log("admin profile", adminProfile);
          if (adminProfile) {
            appVersion = adminProfile.appVersion;
            let currentVersion = "";
            this.appVersion.getVersionNumber().then((data) => {
              currentVersion = data;
              // console.log(
              //   currentVersion.toString() == appVersion,
              //   "currentVersion.toString() == appVersion"
              // );
              // console.log(
              //   currentVersion.toString(),
              //   appVersion,
              //   "currentVersion.toString() == appVersion"
              // );
              // console.log(
              //   typeof currentVersion.toString(),
              //   typeof appVersion,
              //   "currentVersion.toString() == appVersion"
              // );
              
              if (currentVersion.toString() == appVersion) {
                localStorage.setItem("appVersion", appVersion);
              }else{
                this.openUpdateAvailableDialog();
              }

            });
          }
        }
      });
    });
  }

  async openUpdateAvailableDialog() {
    console.log(this.appVersion, "app version");

    const alert = await this.alertController.create({
      cssClass: "Update Available",
      header: "Update Available",
      message: "Please Update application for Latest Features",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Update Now",
          handler: () => {
            window.open(
              "https://play.google.com/store/apps/details?id=com.carClient.bookMyDreamCar",
              "_system"
            );
          },
        },
      ],
    });

    await alert.present();
  }

  // pushsetup(){
  //   const options: PushOptions = {
  //     android: {
  //       senderID:"35150350528"
  //     },
  //     ios: {
  //         alert: 'true',
  //         badge: true,
  //         sound: 'false'
  //     },

  //  }

  //  const pushObject: PushObject = this.push.init(options);

  //  pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));

  //  pushObject.on('registration').subscribe((deviceData: any) => {
  //    console.log('Device registered', deviceData);
  //    this._UserData
  //    .updateProfile('5ea4259790a47f96283ee75d', {
  //      device_token : deviceData.registrationId || ''
  //    })
  //    .subscribe(async (data: any) => {
  //      console.log("data", data);
  //     //  alert("success" + deviceData.registrationId )

  //    });
  //   });

  //  pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));

  // }

  refesh($event) {
    this.shopName = localStorage.getItem("shopName");
    // this._UserData
    //   .getCustomerDetails(localStorage.getItem("userId"))
    //   .subscribe((data: any) => {
    //     console.log(data, "---");
    //     this.userImage = data.CustomerImage;
    //     this.userName = data.fullName;
    //     $event.target.complete();
    //   });
  }

  getStoreList() {
    this._ProductService.fetchStoreList().subscribe((data: any) => {
      this.storeList = data.storeList;
      localStorage.setItem(
        "shopID",
        this.storeList.length ? this.storeList[0]._id : null
      );
      // console.log(this.storeList);
    });
  }

  initializeApp() {
   
    this.platform.ready().then(() => {
   
      this.shopName = localStorage.getItem("shopName");
      // this.statusBar.styleDefault();

      if (!window.AndroidNotch) {
        this.statusBar.hide();
      } else {
        this.statusBar.backgroundColorByHexString("#282b3d");
        this.statusBar.styleLightContent();
      }

      if (window.cordova && window.cordova.plugins.Keyboard) {
        // alert(window.cordova.plugins)
        // Keyboard.show();
        // window.cordova.plugins.Keyboard.enableProdMode(true);
      }

      this.splashScreen.hide();
      timer(10000).subscribe(() => (this.showSpash = false));

      if (localStorage.getItem("AuthToken")) {
        this.getStoreList();
        this.checkApplicationUpadte();
        // this._UserData
        //   .getCustomerDetails(localStorage.getItem("userId"))
        //   .subscribe((data: any) => {
        //     console.log(data, "---");
        //     this.userImage = data.CustomerImage;
        //     this.userName = data.fullName;
        //   });
      }

      // this.pushsetup()
    });

    //   this.fcm.subscribeToTopic("marketing");

    //   this.fcm.getToken().then((token) => {
    //     // backend.registerToken(token);
    //     console.log("getToken" + token)
    //   });

    //   this.fcm.onNotification().subscribe((data) => {
    //     if (data.wasTapped) {
    //       console.log("Received in background");
    //     } else {
    //       console.log("Received in foreground");
    //     }
    //   });

    //   this.fcm.onTokenRefresh().subscribe((token) => {
    //     // backend.registerToken(token);
    //      console.log("refersh Token" + token)
    //   });
  }

  // checkLoginStatus() {
  //   return this.userData.isLoggedIn().then(loggedIn => {
  //     return this.updateLoggedInStatus(loggedIn);
  //   });
  // }

  // updateLoggedInStatus(loggedIn: boolean) {
  //   setTimeout(() => {
  //     this.loggedIn = loggedIn;
  //   }, 300);
  // }

  // listenForLoginEvents() {
  //   window.addEventListener("user:login", () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener("user:signup", () => {
  //     this.updateLoggedInStatus(true);
  //   });

  //   window.addEventListener("user:logout", () => {
  //     this.updateLoggedInStatus(false);
  //   });
  // }

  logout() {
    localStorage.removeItem("userId");
    localStorage.removeItem("AuthToken");
    return this.router.navigateByUrl("/tabs/home");
  }

  // openTutorial() {
  //   this.menu.enable(false);
  //   this.storage.set("ion_did_tutorial", false);
  //   this.router.navigateByUrl("/tutorial");
  // }

  share() {
    this.platform.ready().then(async () => {
      await this.socialSharing
        .share(
          "Download a 2 z Car to get Best offer now - " +
            "https://play.google.com/store/apps/details?id=com.carClient.bookMyDreamCar"
        )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  // logout(){

  // }
}
