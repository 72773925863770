import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { ProductDetailsPage} from "./pages/productDetails//productDetails";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'address/:cartId',
    loadChildren: () => import('./pages/address/address.module').then(m => m.AddressPageModule)
  },
  {
    path: 'manage-address',
    loadChildren: () => import('./pages/manage-address/manage-address.module').then(m => m.ManageAddressPageModule)
  },
  {
    path: 'primary-details',
    loadChildren: () => import('./pages/primaryDetails/primaryDetails.module').then(m => m.PrimaryDetailsModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'compare/:id',
    loadChildren: () => import('./pages/carCompare/carCompare.module').then(m => m.CarComparePageModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  // },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule)
  },

  {
    path: 'trackOrder',
    loadChildren: () => import('./pages/trackOrder/trackOrder.module').then(m => m.TrackOrderPageModule)
  },
  {
    path: 'referal',
    loadChildren: () => import('./pages/referal/referal.module').then(m => m.ReferalPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then(m => m.WalletPageModule)
  },
  {
    path: 'EVP',
    loadChildren: () => import('./pages/EVP/EVP.module').then(m => m.EVPPageModule)
  },
  {
    path: 'show-interest/:service/:car',
    loadChildren: () => import('./pages/showInterest/showInterest.module').then(m => m.ShowInterestPageModule)
  },

  {
    path: 'menu/:id',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'search/:from',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'checkout/:cartId/:addressId',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'wishList',
    loadChildren: () => import('./pages/wishList/wishList.module').then(m => m.WishListModule)
  },
  {
    path: 'orderSummary/:orderId',
    loadChildren: () => import('./pages/orderSummary/orderSummary.module').then(m => m.OrderSummaryPageModule)
  },
  {
    path: 'order-details/:orderId',
    loadChildren: () => import('./pages/orderDetails/orderDetails.module').then(m => m.OrderDetailsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'offers/:filter',
    loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'review/:productId',
    loadChildren: () => import('./pages/review/review.module').then(m => m.ReviewPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'productDetails/:id',
    // component:ProductDetailsPage
    loadChildren: () => import('./pages/productDetails/productDetails.module').then(m => m.ProductDetailsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
