import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderState} from './loader-state.model';
import {PageLoaderService} from '../page-loader.service';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  // providers:[PageLoaderService]
})
export class PageLoaderComponent implements OnInit, OnDestroy {

  public show = false;
  private subscription;

  constructor(private loaderService: PageLoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}


