import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  public ownerId = "5f6f0e8c1f46bf3f11660245"
  // public baseUrl: String = "//localhost:3000"

  public baseUrl: String = environment.baseUrl

  constructor(private http: HttpClient) { }


  getCustomerDetails(userId) {
    let response;
    return this.http.get(this.baseUrl + `/api/v1/Customer/ViewProfile/${userId}`).pipe(
      map((data) => {
        response = data
        return response.object
      })
    );
  }

  getPinodeData(pinode){
    let response;
    return this.http.get(this.baseUrl + `/api/v1/customer/ValidatePincode/${pinode}`).pipe(
      map((data) => {
        response = data
        return response.object
      })
    );
  }

  updateProfile(userId, payload) {
    let response;
    return this.http.put(this.baseUrl + `/api/v1/Customer/ProfileUpdate/${userId}`, payload).pipe(
      map((data) => {
        response = data
        return response.object
      })
    );
  }

  getOwnerId() {
    return this.ownerId
}

   uploadImage(payload) {
        let response;
        return this.http.post(this.baseUrl + `/api/v1/Admin/saveAllImages`, payload).pipe(
            map((data) => {
                response = data
                return response.object
            })
        );

    }



  deleteAddress(addressId) {
    let response;
    return this.http.delete(this.baseUrl + `/api/v1/Customer/deleteAddress/${addressId}`).pipe(
      map((data) => {
        response = data
        return response.object
      })
    );
  }

  editAddress(addressId, payload) {
    let response;
    return this.http.put(this.baseUrl + `/api/v1/Customer/editAddress/${addressId}`, payload).pipe(
      map((data) => {
        response = data
        return response.object
      })
    );
  }








  //


}
