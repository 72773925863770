// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  baseUrl:"https://api.csdcar.com",
  // baseUrl:"http://18.222.219.166:3000",
  // baseUrl:"http://52.15.169.139:3000",
  // baseUrl:   "//localhost:3000",
  FACEBOOK_CLIENT_KEY:'279933619944607',
  // GOOGLE_CLIENT_KEY: '134691487730-7kbj094ot3peru5pfqa9b8fqtvtu7tl5.apps.googleusercontent.com',
  GOOGLE_CLIENT_KEY: '372864392547-h40v4bb5dubm6rgdq5nut1mheghdqp0m.apps.googleusercontent.com',
};





/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

