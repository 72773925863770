import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import {MatExpansionModule} from '@angular/material/expansion';
import { ProductDetailsPage } from './productDetails';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ProductDetailsPageRoutingModule } from './productDetails-routing.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProductDetailsPageRoutingModule,
    MatExpansionModule
  ],
  declarations: [
    ProductDetailsPage,
      
  ],
  providers:[CallNumber],
  entryComponents: [
      
  ]
})
export class ProductDetailsPageModule { }
